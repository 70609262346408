import { CityHallLocationDTO } from "./city-hall-location";
import { InventoryDetails } from "./inventory";
import { PageControl } from "./page-control";

export class CreateAsset{
    qrCode: string;
    name: string;
    locationId: boolean;
}

export class AssetDetails {
    qrCode: string;
    inventoryId: string;
    inventory?: InventoryDetails;
    locationId: string;
    location?: CityHallLocationDTO;
    name: string;
    located: boolean;
    assetCode: string;
    serialNumber: number;
    managerId: string;
    managerName: string;
    holderId: string;
    holderName: string;
    incorporationType: 'PURCHASE';
    category: AssetCategoryDTO;
    providerId: string;
    providerName: string;
    purchaseDate: Date;
    invoiceNumber: number;
    acquisitionValue: number;
    depreciatedValue: number;
    netValue: number;
    useType: UseType;
    material: Material;
    materialDescription: string;
    conservation: Conservation;
    situation: string;
    observation: string;
    imgUrls: string[];
    percentageFilled: number;
    createdAt: Date;
    createdBy: string;
    createdByName: string;
    updatedAt: Date;
    updatedBy: string;
    updatedByName: string;
    receivedObservation: string;
    assetCategoryId?: string;
    assetCategoryTypeId?: string;
  }

  export class AssetCategoryDTO {
    id: string;
    name: string;
    annualDepreciation: number;
    annualDepreciationLabel: string;
    usefulLife: number;
  }

  export class UpdateDetails {
    qrCode: any;
    assetCategoryId: string;
    serialNumber: number;
    managerId: string;
    holderId: string;
  }

  export class UpdateIncorporation {
    qrCode: any;
    incorporationType: IncorporationType;
    providerId: string;
    invoiceNumber: number;
    acquisitionValue: number;
  }

  export class UpdateCharacteristics {
    qrCode: any;
    useType: UseType;
    material: Material;
    materialDescription: string;
    conservation: Conservation;
    situation: string;
  }

  export class UpdateObservation{
    qrCode: any;
    observation: string;
  }

  export class UpdateAddress {
    qrCode: any;
    zipCode: string;
    street: string;
    number: number;
    district: string;
    cityId: string;
    uf: string;
    additionalData: string;
  }

  export class UpdateImgs {
    qrCode: any;
    imgUrls: any;
  }

  export class AttachmentAndImagesDTO{
    images: ImageDataTable[];
    attachments: AttachmentDTO[];
  }

  interface ContentItem {
    fileName: string;
    responsibleUser: string;
    totalRecords: number;
    importDate: string;
    status: string;
  }

  interface SortInfo {
    sorted: boolean;
    empty: boolean;
    unsorted: boolean;
  }

  interface Pageable {
    pageNumber: number;
    pageSize: number;
    sort: SortInfo;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  }

  export class ImageDataTableHistoryImports {
    fileName: string;
    responsibleUser: string;
    totalRecords: number;
    importDate: Date;
    status: string;
  }

  export class AssetHistoryImportsFilter {
    pageControl: PageControl;
  }

  export class AttachmentDTO {
    name: string;
    url: string;
    contentType: string;
    createdBy: string;
    createdByName: string
  }

  export enum IncorporationType {
    'RENT' = 'Aluguel',
    'PURCHASE' = 'Compra',
    'COMMODATUM' = 'Comodato',
    'DONATION' = 'Doação',
    'INCORPORATION' = 'Incorporação',
    'EXCHANGE' = 'Permuta'
  }

  export enum UseType {
    'IN_USE' = 'Em uso',
    'AVAILABLE' = 'Disponível',
    'UNDER_REPAIR' = 'Em reparo',
    'UNAVAILABLE' = 'Indisponível',
    'RESERVED' = 'Reservado'
  }

  export enum Material {
    'WOOD' = 'Madeira',
    'IRON' = 'Ferro',
    'STEEL' = 'Aço',
    'PLASTIC' = 'Plástico',
    'GLASS' = 'Vidro',
    'ALUMINUM' = 'Alumínio'
  }

  export enum Conservation {
    'GREAT' = 'Excelente',
    'GOOD' = 'Bom',
    'FAIR' = 'Regular',
    'BAD' = 'Péssimo'
  }

  export enum AssetState {
    'ACTIVE' = 'Ativo',
    'WRITTEN_OFF' = 'Baixado',
    'SURVEY' = 'Levantamento',
    'REASSESSMENT' = 'Reavaliação'
  }

  export enum PlotAreaMeasures {
    M2 = 'M²',
    M = 'M',
    KM = 'KM',
    UN = 'Un',
    HA = 'Ha'
  }

  export class AssetFilter {
    pageControl: PageControl;
    // inventoryId: string
    locationId: string
    onlyWithoutLocation: boolean
    codes: any[]
    assetType: string
  }

  export class AssetFileUpload {

  }

  export class ImageDataTable {
    locationId: string;
    imgUrl: string;
    address: Address;
    placeId: string;
    coordinates: Coordinates;
    createdBy: string;
    createdByName: string;
    createdAt: string;
  }

  export interface IAssetDocument {
    name: string;
    url: string;
    contentType: string;
    createdBy: string;
    createdByName: string;
  }


  export class Address {
    street: string;
    number: number;
    district: string;
    cityId: string;
    cityName: string;
    uf: string;
    zipCode: string;
  }

  export class Coordinates {
    x: number;
    y: number;
  }
