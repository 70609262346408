import { Component, OnInit, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-ng2-file-drop',
	templateUrl: './ng2-file-drop.component.html',
	styleUrls: ['./ng2-file-drop.component.scss']
})
export class Ng2FileDropComponent implements OnInit {
	@Input() uploader$: Observable<FileUploader>;
	@Input() multiple: boolean;
	@Input() imgUrl: string;

	@Input() labelText: string;
	@Input() labelButton: string;
	@Input() labelButtonTwo: string;
  @Input() uploadDesabilitado: boolean = false;

	@Input() typeUpload: string = 'default';

	constructor() { }

	ngOnInit() { }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

  }

}
