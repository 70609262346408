import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AssetState, Conservation, IncorporationType } from '@app-model/asset';
import { AssetCategoryDetails } from '@app-model/asset-category';
import { AssetCategoryService } from '@app-services/asset-category.service';
import { FilterService, Filter, SelectedFilter } from '@app-services/filter.service';
import { LocationService } from '@app-services/location.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, map, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'app-side-filter',
  templateUrl: './side-filter.component.html',
  styleUrls: ['./side-filter.component.scss']
})
export class SideFilterComponent implements OnInit {

  public filter: Filter;
  public todayDate = new Date();

  myControl = new FormControl('');
  options: any[] = [];
  filteredOptions: Observable<any[]>;

  categories: AssetCategoryDetails[] = [];

  assetStateLabel = AssetState;
  assetState = Object.keys(AssetState);

  myControlClass = new FormControl('');
  filteredOptionsClass: Observable<any[]>;

  convervations = Object.keys(Conservation);
  ConvervationLabel = Conservation;

  IncorporationTypeLabel = IncorporationType;
  incorporationTypes = Object.keys(IncorporationType);

  // Variável para armazenar a string de códigos digitados
  public codesInput: string = '';

  // Lista de filtros selecionados
  selectedFilters: SelectedFilter[] = [];

  private selectedLocationOption: any = null;

  selectedCategoryName: string | null = null;

  constructor(
    public readonly filterService: FilterService,
    private readonly toastr: ToastrService,
    private readonly locationService: LocationService,
    private readonly categoryService: AssetCategoryService
  ) { }

  ngOnInit(): void {
    this.filterService.filter$.subscribe(filter => {
      this.filter = filter;
    });

    this.filterService.selectedFilters$.subscribe(filters => {
      this.selectedFilters = filters;
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      switchMap(value => this._filter(value || ''))
    );

    this.filteredOptionsClass = this.myControlClass.valueChanges.pipe(
      startWith(''),
      map(value => this._filterClass(value || ''))
    );

    this.initAssetCategories();
  }

  public submitFilter(): void {

    // =============== TIPO DO BEM ===============
    if (this.filter.assetType === null) {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Tipo do bem');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Tipo do bem', key: '', display: '' });
      }
    } else if (this.filter.assetType) {
      const display = this.filter.assetType === 'NO_EXIST' ? 'Não preenchido' :
                this.filter.assetType === 'MOVABLE' ? 'Bem móvel' :
                'Bem imóvel';
      this.filterService.addSelectedFilter({
        key: this.filter.assetType,
        label: 'Tipo do bem',
        display
      });
    }

    // =============== DATA DE INCORPORAÇÃO (Início) ===============
    if (this.filter.incorporatedDateStart) {
      const dateISO = new Date(this.filter.incorporatedDateStart).toISOString();
      const dateDisplay = new Date(this.filter.incorporatedDateStart).toLocaleDateString();
      this.filterService.addSelectedFilter({
        key: dateISO,
        label: 'Data de incorporação (Início)',
        display: dateDisplay
      });
    } else {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Data de incorporação (Início)');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Data de incorporação (Início)', key: '', display: '' });
      }
    }

    // =============== DATA DE INCORPORAÇÃO (Fim) ===============
    if (this.filter.incorporatedDateEnd) {
      const dateISO = new Date(this.filter.incorporatedDateEnd).toISOString();
      const dateDisplay = new Date(this.filter.incorporatedDateEnd).toLocaleDateString();
      this.filterService.addSelectedFilter({
        key: dateISO,
        label: 'Data de incorporação (Fim)',
        display: dateDisplay
      });
    } else {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Data de incorporação (Fim)');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Data de incorporação (Fim)', key: '', display: '' });
      }
    }

    // =============== DATA DE AQUISIÇÃO (Início) ===============
    if (this.filter.purchaseDateStart) {
      const dateISO = new Date(this.filter.purchaseDateStart).toISOString();
      const dateDisplay = new Date(this.filter.purchaseDateStart).toLocaleDateString();
      this.filterService.addSelectedFilter({
        key: dateISO,
        label: 'Data de aquisição (Início)',
        display: dateDisplay
      });
    } else {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Data de aquisição (Início)');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Data de aquisição (Início)', key: '', display: '' });
      }
    }

    // =============== DATA DE AQUISIÇÃO (Fim) ===============
    if (this.filter.purchaseDateEnd) {
      const dateISO = new Date(this.filter.purchaseDateEnd).toISOString();
      const dateDisplay = new Date(this.filter.purchaseDateEnd).toLocaleDateString();
      this.filterService.addSelectedFilter({
        key: dateISO,
        label: 'Data de aquisição (Fim)',
        display: dateDisplay
      });
    } else {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Data de aquisição (Fim)');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Data de aquisição (Fim)', key: '', display: '' });
      }
    }

    // =============== DATA DE ATUALIZAÇÃO (Início) ===============
    if (this.filter.updateAtStart) {
      const dateISO = new Date(this.filter.updateAtStart).toISOString();
      const dateDisplay = new Date(this.filter.updateAtStart).toLocaleDateString();
      this.filterService.addSelectedFilter({
        key: dateISO,
        label: 'Data de atualização (Início)',
        display: dateDisplay
      });
    } else {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Data de atualização (Início)');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Data de atualização (Início)', key: '', display: '' });
      }
    }

    // =============== DATA DE ATUALIZAÇÃO (Fim) ===============
    if (this.filter.updateAtEnd) {
      const dateISO = new Date(this.filter.updateAtEnd).toISOString();
      const dateDisplay = new Date(this.filter.updateAtEnd).toLocaleDateString();
      this.filterService.addSelectedFilter({
        key: dateISO,
        label: 'Data de atualização (Fim)',
        display: dateDisplay
      });
    } else {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Data de atualização (Fim)');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Data de atualização (Fim)', key: '', display: '' });
      }
    }

    // =============== LOCALIZAÇÃO ===============
    // Se houver uma opção selecionada, adiciona o filtro; caso contrário, mantém o filtro atual (o usuário pode removê-lo manualmente)
    console.log('selectedLocationOption', this.selectedLocationOption);
    if (this.selectedLocationOption != null && this.selectedLocationOption !== 'NO_EXIST') {
      this.filter.locationId = this.selectedLocationOption.id;
      this.filterService.addSelectedFilter({
        key: this.filter.locationId,
        label: 'Localização',
        display: this.selectedLocationOption.fullName
      });
    }

    if(this.selectedLocationOption === 'NO_EXIST') {
      this.filter.locationId = 'NO_EXIST';
      this.filterService.addSelectedFilter({
        key: 'NO_EXIST',
        label: 'Localização',
        display: 'NO_EXIST'
      });
    }

    // =============== STATUS DO BEM ===============
    if (this.filter.assetState === null) {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Status do bem');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Status do bem', key: '', display: '' });
      }
    } else if (this.filter.assetState) {
      this.filterService.addSelectedFilter({
        key: this.filter.assetState,
        label: 'Status do bem',
        display: this.assetStateLabel[this.filter.assetState] || this.filter.assetState
      });
    }

    // =============== CLASSE DO ATIVO ===============
    if (this.filter.assetCategoryId === null) {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Classe do ativo');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Classe do ativo', key: '', display: '' });
      }
    } else if (this.filter.assetCategoryId) {
      this.filterService.addSelectedFilter({
        key: this.filter.assetCategoryId,
        label: 'Classe do ativo',
        display: this.selectedCategoryName
      });
    }

    // =============== ESTADO DE CONSERVAÇÃO ===============
    if (this.filter.conservation === null) {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Estado de conservação');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Estado de conservação', key: '', display: '' });
      }
    } else if (this.filter.conservation) {
      this.filterService.addSelectedFilter({
        key: this.filter.conservation,
        label: 'Estado de conservação',
        display: this.filter.conservation
      });
    }

    // =============== TIPO DE AQUISIÇÃO ===============
    if (this.filter.incorporationType === null) {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Tipo de aquisição');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Tipo de aquisição', key: '', display: '' });
      }
    } else if (this.filter.incorporationType) {
      this.filterService.addSelectedFilter({
        key: this.filter.incorporationType,
        label: 'Tipo de aquisição',
        display: this.filter.incorporationType
      });
    }

    // =============== NOTA FISCAL ===============
    if (!this.filter.invoiceNumber) {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Nota fiscal');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Nota fiscal', key: '', display: '' });
      }
    } else {
      this.filterService.addSelectedFilter({
        key: this.filter.invoiceNumber.toString(),
        label: 'Nota fiscal',
        display: this.filter.invoiceNumber.toString()
      });
    }

    // =============== EMPENHO ===============
    if (!this.filter.purchaseOrderNumber) {
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Empenho');
      if (alreadyInFilters) {
        this.removeSelectedFilter({ label: 'Empenho', key: '', display: '' });
      }
    } else {
      this.filterService.addSelectedFilter({
        key: this.filter.purchaseOrderNumber,
        label: 'Empenho',
        display: this.filter.purchaseOrderNumber
      });
    }

    // =============== CÓDIGOS (múltiplos valores) ===============
    if (this.codesInput) {
      // const codesArray: number[] = Array.from(
      //   new Set(
      //     this.codesInput
      //       .split(',')
      //       .map(code => code.replace(/\s/g, ''))
      //       .filter(code => code !== '')
      //       .map(Number)
      //       .filter(n => !isNaN(n))
      //   )
      // );

      const codesArray: string[] = Array.from(
        new Set(
          this.codesInput
            .split(',')
            .map(code => code.trim())
            .filter(code => code !== '')
        )
      );

      this.filter.codes = codesArray;
      this.filterService.setCodesFilter(codesArray);
    } else {
      this.filter.codes = [];
      const alreadyInFilters = this.selectedFilters.some(f => f.label === 'Códigos');
      if (alreadyInFilters) {
        this.filterService.removeSelectedFilter({ label: 'Códigos', key: '', display: '' });
      }
    }

    // Aplica o objeto filter e fecha o painel
    this.filterService.pushFilter(this.filter);
    this.toastr.success('Filtro atualizado!');
    this.filterService.closeFilter();

    // Limpa somente os controles do formulário (os filtros aplicados permanecem no array)
    this.myControl.setValue('');
    this.codesInput = '';
    this.selectedLocationOption = null;
  }

  public clearFilter(): void {
    this.filterService.clearFilters();
    this.codesInput = '';
    this.selectedLocationOption = null;
    this.myControl.setValue('');
  }

  private _filter(value: string): Observable<any[]> {
    const filterValue = value.toLowerCase();
    return this.locationService.getCityHallLocation(filterValue).pipe(
      map(resp => {
        this.options = resp;
        return this.options.filter(option => option.fullName.toLowerCase().includes(filterValue));
      })
    );
  }

  onOptionSelected(event: any): void {
    const selectedOption = this.options.find(option => option.fullName === event.source.value);
    if (selectedOption) {
      this.filter.locationId = selectedOption.id;
      this.filterService.addSelectedFilter({
        key: selectedOption.id,
        label: 'Localização',
        display: selectedOption.fullName
      });
    }
  }

  private initAssetCategories(): void {
    this.categoryService.getAssetCategories().subscribe(resp => {
      this.categories = resp;
    });
  }

  private _filterClass(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.categories.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  public storeLocation(fullName: string): void {
    const found = this.options.find(opt => opt.fullName === fullName);
    if (found) {
      this.selectedLocationOption = found;
    } else {
      this.selectedLocationOption = null;
    }
    if(fullName === 'Não preenchido') {
      this.selectedLocationOption = 'NO_EXIST';
    }
  }

  public removeSelectedFilter(filter: SelectedFilter): void {
    this.filterService.removeSelectedFilter(filter);
  }

  public removeCode(code: string): void {
    this.filterService.removeCode(code);
  }

  onCategoryChange(selectedId: string | null): void {
    const selected = this.categories.find(c => c.id === selectedId);
    this.selectedCategoryName = selected ? selected.name : 'NO_EXIST';
  }

}
