import { Injectable } from '@angular/core';
import { SessionQuery } from '@app-store/session/session.query';
import { UserInfo } from '@app-store/session/session.store';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Filter {
  codes: string[];
  locationId: string;
  assetType: string;
  incorporatedDateStart: Date;
  incorporatedDateEnd: Date;
  updateAtStart: Date;
  updateAtEnd: Date;
  purchaseDateStart: Date;
  purchaseDateEnd: Date;
  assetCodeStart: string;
  assetCodeEnd: string;
  assetState: string;
  assetCategoryId: string;
  assetCategoryTypeID: string;
  conservation: string;
  incorporationType: string;
  invoiceNumber: number;
  purchaseOrderNumber: string;
  idle: boolean;
}

export interface SelectedFilter {
  key: string;   // Ex.: "assetType", "codes", etc.
  label: string; // Ex.: "Tipo do bem", "Códigos", etc.
  display: any;  // Pode ser string ou array de strings
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  userInfo$: Observable<UserInfo>;

  // Mapeamento de "label" para "key" no objeto Filter
  private labelToKey: Record<string, keyof Filter> = {
    'Códigos': 'codes',
    'Localização': 'locationId',
    'Tipo do bem': 'assetType',
    'Data de incorporação (Início)': 'incorporatedDateStart',
    'Data de incorporação (Fim)': 'incorporatedDateEnd',
    'Data de atualização (Início)': 'updateAtStart',
    'Data de atualização (Fim)': 'updateAtEnd',
    'Data de aquisição (Início)': 'purchaseDateStart',
    'Data de aquisição (Fim)': 'purchaseDateEnd',
    'Plaqueta (De)': 'assetCodeStart',
    'Plaqueta (Até)': 'assetCodeEnd',
    'Status do bem': 'assetState',
    'Classe do ativo': 'assetCategoryId',
    'Estado de conservação': 'conservation',
    'Tipo de aquisição': 'incorporationType',
    'Nota fiscal': 'invoiceNumber',
    'Empenho': 'purchaseOrderNumber',
    'Bem ocioso': 'idle'
    // Adicione conforme necessário
  };

  private _filter: BehaviorSubject<Filter> = new BehaviorSubject<Filter>({
    codes: [],
    locationId: '',
    assetType: null,
    incorporatedDateStart: null,
    incorporatedDateEnd: null,
    updateAtStart: null,
    updateAtEnd: null,
    purchaseDateStart: null,
    purchaseDateEnd: null,
    assetCodeStart: null,
    assetCodeEnd: null,
    assetState: null,
    assetCategoryId: null,
    assetCategoryTypeID: null,
    conservation: null,
    incorporationType: null,
    invoiceNumber: null,
    purchaseOrderNumber: null,
    idle: false
  });

  private _isOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _selectedFilters: BehaviorSubject<SelectedFilter[]> = new BehaviorSubject<SelectedFilter[]>([]);

  public filter$: Observable<Filter> = this._filter.asObservable();
  public filterStatus$: Observable<boolean> = this._isOpened.asObservable();
  public selectedFilters$: Observable<SelectedFilter[]> = this._selectedFilters.asObservable();

  constructor(private sessionQuery: SessionQuery) {}

  // Atualiza o objeto Filter
  public pushFilter(filterUpdate: Partial<Filter>): void {
    const currentFilter = this._filter.value;
    const updatedFilter = { ...currentFilter, ...filterUpdate };
    this._filter.next(updatedFilter);
  }

  // Remove todo um campo (ex.: removeFilter('codes') para zerar o array)
  public removeFilter(key: keyof Filter): void {
    const currentFilter = { ...this._filter.value };
    if (Array.isArray(currentFilter[key])) {
      (currentFilter as any)[key] = [];
    } else {
      (currentFilter as any)[key] = typeof currentFilter[key] === 'boolean' ? false : null;
    }
    this._filter.next(currentFilter);

    // Remove também de selectedFilters, se houver
    const label = Object.entries(this.labelToKey).find(([lab, k]) => k === key)?.[0];
    if (label) {
      this.removeSelectedFilter({ label, key: key.toString(), display: '' });
    }
  }

  // Limpa todos os campos
  public clearFilters(): void {
    this._filter.next({
      codes: [],
      locationId: '',
      assetType: null,
      incorporatedDateStart: null,
      incorporatedDateEnd: null,
      updateAtStart: null,
      updateAtEnd: null,
      purchaseDateStart: null,
      purchaseDateEnd: null,
      assetCodeStart: null,
      assetCodeEnd: null,
      assetState: null,
      assetCategoryId: null,
      assetCategoryTypeID: null,
      conservation: null,
      incorporationType: null,
      invoiceNumber: null,
      purchaseOrderNumber: null,
      idle: false
    });
    this._selectedFilters.next([]);
  }

  public openFilter(): void {
    this._isOpened.next(true);
  }

  public closeFilter(): void {
    this._isOpened.next(false);
  }

  public saveFilterState(): void {
    localStorage.setItem('filterState', JSON.stringify(this._filter.value));
  }

  public loadFilterState(): void {
    const savedFilter = localStorage.getItem('filterState');
    if (savedFilter) {
      this._filter.next(JSON.parse(savedFilter));
    }
  }

  public handleFilterStatus(): Observable<boolean> {
    return this._isOpened.asObservable();
  }

  // "Códigos": exibe tudo em um SelectedFilter
  public setCodesFilter(codesArray: string[]): void {
    const codesStringArray = codesArray.map(c => c.toString());

    // Se o array estiver vazio, remove o filtro de códigos (se existir) e sai
    if (codesStringArray.length === 0) {
      this.removeFilter('codes'); // já cuida de tudo (Filter + selectedFilters)
      return;
    }

    const currentFilters = this._selectedFilters.value;
    const index = currentFilters.findIndex(item => item.label === 'Códigos');

    const updatedFilter: SelectedFilter = {
      key: 'codes',
      label: 'Códigos',
      display: codesStringArray
    };

    if (index > -1) {
      currentFilters[index] = updatedFilter;
      this._selectedFilters.next([...currentFilters]);
    } else {
      this._selectedFilters.next([...currentFilters, updatedFilter]);
    }
  }


  // Remove um único código do array
  public removeCode(code: string): void {
    const currentFilter = { ...this._filter.value };
    currentFilter.codes = currentFilter.codes.filter(c => c.toString() !== code);
    this._filter.next(currentFilter);

    const currentFilters = [...this._selectedFilters.value];
    const index = currentFilters.findIndex(item => item.label === 'Códigos');
    if (index > -1) {
      const codesDisplay: string[] = currentFilters[index].display;
      const updatedDisplay = codesDisplay.filter(c => c !== code);
      currentFilters[index] = {
        key: 'codes',
        label: 'Códigos',
        display: updatedDisplay
      };
      this._selectedFilters.next(currentFilters);
    }
  }

  // Adiciona um filtro "simples" (não "Códigos")
  public addSelectedFilter(filter: SelectedFilter): void {
    if (filter.key === null) {
      return;
    }
    if (filter.label === 'Códigos') {
      return; // Use setCodesFilter para códigos
    }
    const currentFilters = this._selectedFilters.value;
    const index = currentFilters.findIndex(item => item.label === filter.label);
    if (index > -1) {
      currentFilters[index] = filter;
      this._selectedFilters.next([...currentFilters]);
    } else {
      this._selectedFilters.next([...currentFilters, filter]);
    }
  }

  /**
   * Remove um filtro "simples" (label) e limpa o campo no objeto Filter.
   * Para "Códigos", usar removeFilter('codes') ou removeCode(...) em vez disso.
   */
  public removeSelectedFilter(filter: SelectedFilter): void {
    if (filter.label === 'Códigos') {
      return; // A remoção total de códigos é removeFilter('codes'), e parcial é removeCode(...)
    }

    // 1) Remove do selectedFilters
    const currentFilters = this._selectedFilters.value;
    const updatedFilters = currentFilters.filter(item => item.label !== filter.label);
    this._selectedFilters.next(updatedFilters);

    // 2) Limpa a propriedade no filter, usando labelToKey
    const key = this.labelToKey[filter.label];
    if (key) {
      const currentFilter = { ...this._filter.value };
      if (Array.isArray(currentFilter[key])) {
        (currentFilter as any)[key] = [];
      } else {
        (currentFilter as any)[key] = typeof currentFilter[key] === 'boolean' ? false : null;
      }
      this._filter.next(currentFilter);
    }
  }
}
