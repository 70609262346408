<div class="row p-3">
  <div class="title-side-filter col-12 d-flex justify-content-between align-items-center">
    <h1 class="m-0">Selecionar os filtros</h1>
    <i class="fal fa-angle-right m-0" (click)="filterService.closeFilter()"></i>
  </div>
</div>

<div class="row p-3">
  <div class="col-6">
    <button mat-flat-button class="btn btn-clear mx-2" (click)="clearFilter(); filterService.closeFilter()">
      <i class="fal fa-trash-alt mr-2"></i>
      Limpar Filtros
    </button>
  </div>
  <div class="col-6 text-right">
    <button mat-stroked-button class="btn btn-cancel mx-2" (click)="filterService.closeFilter()">
      Cancelar
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<ng-container>
  <div class="container-filter">
    <!-- Campo: Tipo do bem -->
    <div class="row container-row-form mt-1 p-2">
      <div class="col-12 mb-1">
        <h1>Tipo do bem</h1>
      </div>
      <div class="col-12 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>Tipo do bem</mat-label>
          <mat-select [(value)]="filter.assetType">
            <mat-option [value]="null">Todos</mat-option>
            <mat-option value="NO_EXIST">Não preenchido</mat-option>
            <mat-option value="MOVABLE">Bem móvel</mat-option>
            <mat-option value="REAL_STATE">Bem imóvel</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Intervalo de plaquetas -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Intervalo de plaquetas</h1>
      </div>
      <div class="col-6 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>DE</mat-label>
          <input type="text" matInput [(ngModel)]="filter.assetCodeStart" />
        </mat-form-field>
      </div>
      <div class="col-6 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>ATÉ</mat-label>
          <input type="text" matInput [(ngModel)]="filter.assetCodeEnd" />
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Data de incorporação -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Data de incorporação</h1>
      </div>
      <div class="col-6 appearance-input-filter">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="picker" [max]="todayDate" placeholder="00/00/0000" (click)="picker.open()" [(ngModel)]="filter.incorporatedDateStart" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-6 appearance-input-filter">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="pickerIncorpation" [max]="todayDate" placeholder="00/00/0000" (click)="pickerIncorpation.open()" [(ngModel)]="filter.incorporatedDateEnd" />
          <mat-datepicker-toggle matSuffix [for]="pickerIncorpation"></mat-datepicker-toggle>
          <mat-datepicker #pickerIncorpation></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Data de aquisição -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Data de aquisição</h1>
      </div>
      <div class="col-6 appearance-input-filter">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="pickerPurchase" [max]="todayDate" placeholder="00/00/0000" (click)="pickerPurchase.open()" [(ngModel)]="filter.purchaseDateStart" />
          <mat-datepicker-toggle matSuffix [for]="pickerPurchase"></mat-datepicker-toggle>
          <mat-datepicker #pickerPurchase></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-6 appearance-input-filter">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="pickerPurchaseEnd" [max]="todayDate" placeholder="00/00/0000" (click)="pickerPurchaseEnd.open()" [(ngModel)]="filter.purchaseDateEnd" />
          <mat-datepicker-toggle matSuffix [for]="pickerPurchaseEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerPurchaseEnd></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Data de atualização -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Data de atualização</h1>
      </div>
      <div class="col-6 appearance-input-filter">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="pickerUpdate" [max]="todayDate" placeholder="00/00/0000" (click)="pickerUpdate.open()" [(ngModel)]="filter.updateAtStart" />
          <mat-datepicker-toggle matSuffix [for]="pickerUpdate"></mat-datepicker-toggle>
          <mat-datepicker #pickerUpdate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-6 appearance-input-filter">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="pickerUpdateEnd" [max]="todayDate" placeholder="00/00/0000" (click)="pickerUpdateEnd.open()" [(ngModel)]="filter.updateAtEnd" />
          <mat-datepicker-toggle matSuffix [for]="pickerUpdateEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerUpdateEnd></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Localização -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Localização</h1>
      </div>
      <div class="col-12 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>Selecionar</mat-label>
          <input
            type="text"
            matInput
            [formControl]="myControl"
            [matAutocomplete]="autoLocation"
          />
          <mat-autocomplete
            #autoLocation="matAutocomplete"
            (optionSelected)="storeLocation($event.option.value)"s
          >
            <mat-option [value]="null">Todos</mat-option>
            <!-- <mat-option [value]="'Não preenchido'">Não preenchido</mat-option> -->
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.fullName"
            >
              {{ option.fullName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

      </div>
    </div>

    <!-- Campo: Status do bem -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Status do bem</h1>
      </div>
      <div class="col-12 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>Selecionar</mat-label>
          <mat-select [(value)]="filter.assetState">
            <mat-option [value]="null">Todos</mat-option>
            <mat-option value="NO_EXIST">Não preenchido</mat-option>
            <mat-option *ngFor="let type of assetState" [value]="type">
              {{ assetStateLabel[type] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Classe do ativo -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Classe do ativo</h1>
      </div>
      <div class="col-12 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>Selecionar</mat-label>
          <mat-select [(value)]="filter.assetCategoryId" (selectionChange)="onCategoryChange($event.value)">
            <mat-option [value]="null">Todos</mat-option>
            <mat-option value="NO_EXIST">Não preenchido</mat-option>
            <mat-option *ngFor="let category of categories" [value]="category.id">
              {{ category.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Tipo de aquisição -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Tipo de aquisição</h1>
      </div>
      <div class="col-12 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>Selecionar</mat-label>
          <mat-select [(value)]="filter.incorporationType">
            <mat-option [value]="null">Todos</mat-option>
            <mat-option value="NO_EXIST">Não preenchido</mat-option>
            <mat-option *ngFor="let type of incorporationTypes" [value]="type">
              {{ IncorporationTypeLabel[type] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Nota fiscal -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Nota fiscal</h1>
      </div>
      <div class="col-12 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>Digite o número</mat-label>
          <input type="text" matInput [(ngModel)]="filter.invoiceNumber" />
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Empenho -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Empenho</h1>
      </div>
      <div class="col-12 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>Digite o código</mat-label>
          <input type="text" matInput [(ngModel)]="filter.purchaseOrderNumber" />
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Código do bem (múltiplos valores) -->
    <div class="row container-row-form p-2 pt-1">
      <div class="col-12 mb-1">
        <h1>Código do bem</h1>
      </div>
      <div class="col-12 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>DIGITE UM OU MAIS CÓD. SEPARADOS POR VÍRGULA</mat-label>
          <!-- Usa codesInput para receber a string digitada -->
          <input type="text" matInput [(ngModel)]="codesInput" (input)="codesInput = $event.target.value"/>
        </mat-form-field>
      </div>
    </div>

    <!-- Campo: Bem ocioso -->
    <div class="row container-row-form mt-1 p-2">
      <div class="col-12 mb-1">
        <h1>Bem ocioso</h1>
      </div>
      <div class="col-12 appearance-input-filter">
        <mat-form-field appearance="fill">
          <mat-label>Selecionar</mat-label>
          <mat-select [(value)]="filter.idle">
            <mat-option [value]="null">Todos</mat-option>
            <mat-option [value]="true">Sim</mat-option>
            <mat-option [value]="false">Não</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </div>
</ng-container>

<mat-divider></mat-divider>

<ng-container>
  <div class="row justify-content-center mt-3">
    <div class="col-auto">
      <button class="btn btn-apply" (click)="submitFilter()">
        APLICAR FILTROS
      </button>
    </div>
  </div>
</ng-container>
