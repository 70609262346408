import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutSessionComponent } from './layout-session/layout-session.component';
import { RouterModule } from '@angular/router';
import { LayoutBlankComponent } from './layout-blank/layout-blank.component';
import { LayoutRestrictAreaComponent } from './layout-restrict-area/layout-restrict-area.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LayoutRestrictAreaHeaderComponent } from './layout-restrict-area/header/layout-restrict-area-header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from '@app-shared/directives/shared-directives.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule}  from '@angular/material/progress-spinner';
import { SidebarAdminComponent } from './layout-restrict-area/sidebar-admin/sidebar-admin.component';
import { SideFilterModule } from '@app-shared/components/side-filter/side-filter.module';

@NgModule({
	declarations: [
		LayoutSessionComponent,
		LayoutBlankComponent,
		LayoutRestrictAreaComponent,
		LayoutRestrictAreaHeaderComponent,
    SidebarAdminComponent
	],
	imports: [
    CommonModule,
    RouterModule,
    PerfectScrollbarModule,
    NgbModule,
    SharedPipesModule,
    SharedDirectivesModule,
    MatTooltipModule,
    MatSelectModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    SideFilterModule
  ],
	exports: [
    LayoutSessionComponent,
    LayoutRestrictAreaComponent,
    LayoutBlankComponent
  ]
})
export class LayoutsModule {}
