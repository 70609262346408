import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CityHallDTO } from '@app-model/city-hall';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { handleError } from '@app-utils/al-utils';
import { CityHallLocationDTO } from '@app-model/city-hall-location';
import { Page } from '@app-model/campaign';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private http: HttpClient,
    private toastr: ToastrService
  ) { }

  public getCityHallLocation(locationParam: string): Observable<any>{
    const body = { name: locationParam };
    return this.http.get<any>(`${environment.api}/cityHallLocation/find`, { params: body });
  }

  public saveLocation(body: any): Observable<CityHallLocationDTO>{
    return this.http.post<any>(`${environment.api}/cityHallLocation/create`, body)
      .pipe(
        catchError(error => handleError(error, this.toastr))
      )
  }

  public listLocations(filter: any): Observable<Page<CityHallLocationDTO>>{
    return this.http.post<Page<CityHallLocationDTO>>(`${environment.api}/cityHallLocation/list`, filter).pipe(
      catchError((e: HttpErrorResponse) => handleError(e, this.toastr))
    );
  }

  public getLocation(id: string): Observable<CityHallLocationDTO>{
    return this.http.get<CityHallLocationDTO>(`${environment.api}/cityHallLocation/get/${id}`).pipe(
      catchError((e: HttpErrorResponse) => handleError(e, this.toastr))
    );
  }

}
