<ng-container [ngSwitch]="sidebarType">
  <ng-container *ngSwitchCase="'general'" [ngTemplateOutlet]="pathGeneral"></ng-container>
</ng-container>

<ng-template #pathGeneral>
  <mat-drawer-container #drawer [hasBackdrop]="true">
    <mat-drawer-content>
      <app-layout-restrict-area-header [filterDisplay]="false"></app-layout-restrict-area-header>
      <!-- mexi aqui no layout -->
      <div class="wrapper align-items-stretch">
        <mat-drawer-container #d [hasBackdrop]="true">
          <mat-drawer-content [ngClass]="{'d-flex align-items-stretch': true}">
            <app-sidebar-admin></app-sidebar-admin>
            <!-- Page Content  -->
            <div id="content" class="p-3"
              [ngClass]="{ 'sidebar-active': !sidebarService.minSidebar || !sidebarService.active }"
              [style.background]="'linear-gradient(180deg, #F4F8F7 0%, #EBF6F8 100%)'"
              >
              <router-outlet></router-outlet>
              <div class="flex-grow-1"></div>
              <div class="module-loader" *ngIf="moduleLoading">
                <div class="spinner spinner-bubble spinner-bubble-primary mr-3"></div>
              </div>
            </div>
          </mat-drawer-content>
        </mat-drawer-container>
      </div>
    </mat-drawer-content>
    <mat-drawer
      position="end"
      mode="over"
      [opened]="openFilter"
      (openedChange)="handleFilterStatus($event)"
      style="max-width: 380px; width: 100%;"
      autoFocus="false"
      >
        <ng-container [ngSwitch]="filterType">
          <app-side-filter *ngSwitchCase="'default'"></app-side-filter>
        </ng-container>
    </mat-drawer>
  </mat-drawer-container>
</ng-template>


