import { HttpErrorResponse } from "@angular/common/http";
import { inject } from "@angular/core/testing";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";

export const handleError = (errorResponse: HttpErrorResponse, toastr: ToastrService): Observable<never> => {

    const error = errorResponse.error;
    if (error && error.errors && Array.isArray(error.errors)) {
        error.errors.forEach((err: string) => {
            toastr.error(err);
        });
    } else {
        toastr.error('Ocorreu um erro desconhecido ao tentar criar o inventário.', 'Erro');
    }

    return throwError(errorResponse);
}

export const MAX_INT = 2147483647;
