import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetCategoryDetails, AssetType } from '@app-model/asset-category';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetCategoryService {

  constructor(
    private readonly http: HttpClient
  ) { }


  public getAssetCategories(): Observable<AssetCategoryDetails[]> {
    return this.http.get<AssetCategoryDetails[]>(`${environment.api}/assetCategory/findAll`);
  }

  public getAssetType(assetCategoryId: string): Observable<AssetType[]> {
    return this.http.get<AssetType[]>(`${environment.api}/assetCategory/type/${assetCategoryId}`);
  }
}
