import { Component, OnInit, HostListener } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { Utils } from '@app-shared/utils';

import { SidebarService } from '@app-services/sidebar.service';
import { SessionService } from '@app-store/session/session.service';
import { UserInfo } from '@app-store/session/session.store';

@Component({
	selector: 'app-sidebar-admin',
	templateUrl: './sidebar-admin.component.html',
	styleUrls: ['./sidebar-admin.component.scss']
})
export class SidebarAdminComponent implements OnInit {
	isMobile: boolean = false;

	userInfo: UserInfo;

	itemsMenu = [];

	constructor(
		public router: Router,
		public sidebarService: SidebarService,
		private sessionService: SessionService
	) {
		this.sessionService.getUser().subscribe(user => {
			this.userInfo = user;
			this.updateMenu()
		}).add(() => {
			this.updateMenu();
		});
	}

	ngOnInit() {
		this.updateSidebar();
		this.updateActiveMenu();
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(routeChange => {
			if (Utils.isMobile()) {
				this.sidebarService.active = false;
			}
			this.updateActiveMenu();
		});
	}

	updateSidebar() {
		const isMobile = Utils.isMobile();
		this.sidebarService.active = !isMobile;
		this.sidebarService.minSidebar = true;
		this.isMobile = !isMobile;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateSidebar();
	}

	setActiveMenu(item) {
		item.dropdown = !item.dropdown;
	}

	private updateActiveMenu() {
		const activeRoute = window.location.hash || window.location.pathname;
		this.itemsMenu.forEach(item => {
			item.dropdown = false;
			if (activeRoute.indexOf(item.link) !== -1) {
				item.dropdown = true;
			}
			if (item.children) {
				item.children.forEach(subItem => {
					// subItem.active = false;
					if (activeRoute.indexOf(subItem.link) !== -1) {
						item.dropdown = true;
					}
				});
			}
		});
	}

	toggleSidebar() {
		this.sidebarService.minSidebar = !this.sidebarService.minSidebar;
	}

	updateMenu(): void {
		const canCreateInventory = this.userInfo?.hasPermission('CREATE_INVENTORY');
		const canListInventory = this.userInfo?.hasPermission('LIST_INVENTORY');
		const canCreateAsset = this.userInfo?.hasPermission('CREATE_ASSET');
		const canListMovement = this.userInfo?.hasPermission('LIST_MOVEMENT');
		const canListUser = this.userInfo?.hasPermission('LIST_USER');
		const canListAccessLevel = this.userInfo?.hasPermission('LIST_USER_ROLE');
    const canListReports = this.userInfo?.hasPermission('LIST_USER_ROLE');

    if (canCreateInventory) {
			this.itemsMenu.push({
				type: 'dropdown',
				label: 'Localização',
				link: '/painel/general/inventory/new-inventory',
				icon: 'fa-globe',
				dropdown: false,
				children: [ {
					label: 'Cadastrar',
					link: '/painel/general/inventory/new-inventory',
				},
        {
					label: 'Listagem',
					link: '/painel/general/inventory/list',
				},]
			})
		}

		// if (canListInventory) {
		// 	this.itemsMenu.push({
		// 		type: 'link',
		// 		label: 'Inventários',
		// 		link: '/painel/general/inventory/list',
		// 		icon: 'fa-folders',
		// 		dropdown: false,
		// 		children: []
		// 	})
		// }

    if (canCreateAsset) {
			this.itemsMenu.push({
				type: 'dropdown',
				label: 'Patrimônio',
				link: '/painel/general/asset/register',
				icon: 'fa-layer-plus',
				dropdown: false,
				children: [
        {
					label: 'Cadastrar',
					link: '/painel/general/asset/register'
				},
        {
					label: 'Importar bens',
					link: '/painel/general/asset/importAsset'
				},
        {
					label: 'Sem localização',
					link: '/painel/general/inventory/wihoutLocation'
				}
      ]
			})
		}

		if (canListMovement) {
			this.itemsMenu.push({
				type: 'link',
				label: 'Movimentar',
				link: '/painel/general/movement/list',
				icon: 'fa-route',
				dropdown: false,
				children: []
			})
		}

		if (canListUser) {
			this.itemsMenu.push({
				type: 'link',
				label: 'Usuários',
				link: '/painel/general/user/list',
				icon: 'fa-users',
				dropdown: false,
				children: []
			})
		}

		if (canListAccessLevel) {
			this.itemsMenu.push({
				type: 'link',
				label: 'Níveis de acesso',
				link: '/painel/general/user/access-level/list',
				icon: 'fa-user-shield',
				dropdown: false,
				children: []
			})
		}

    if (canListReports) {
      this.itemsMenu.push({
				type: 'dropdown',
				label: 'Relatórios',
				link: '/painel/general/reports/synthetic',
				icon: 'fa-table',
				dropdown: false,
				children: [
        {
					label: 'Sintético',
					link: '/painel/general/reports/synthetic'
				},
        {
					label: 'Analítico',
					link: '/painel/general/reports/analytics'
				},
      ]
			})
    }
	}

}
