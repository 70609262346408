import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideFilterComponent } from './side-filter.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from '@app-shared/shared.module';


@NgModule({
  declarations: [
    SideFilterComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    SharedModule
  ],
  exports: [
    SideFilterComponent
  ]
})
export class SideFilterModule { }
